import socket from '../socket';

export default async function socketRequest(event, data = {}) {
  let requsetData = {};
  // Global add websocket Authentication
  if (event === 'login') {
    requsetData = {
      ...data,
      app: 'DRIVER',
      clientId: 'WEB',
      token: localStorage.getItem('accessToken') || '',
      remToken: localStorage.getItem('remToken') || '',
    }
  } else {
    requsetData = {
      ...data,
      originToken: {
        app: 'DRIVER',
        clientId: 'WEB',
        token: localStorage.getItem('accessToken') || '',
        remToken: localStorage.getItem('remToken') || '',
      }
    }
  }
  return new Promise((resolve, reject) => {
    socket.emit(event, requsetData, res => {
      if (typeof res === 'string') {
        resolve(null);
      } else {
        resolve(res);
      }
    });
  }).catch(e => {
    console.log(e);
  });
}
