import { extend } from 'umi-request';
import { notification } from 'antd';

import router from 'umi/router';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * error handle
 */
const errorHandler = error => {
  const { response = {} } = error;
  const errortext = codeMessage[response?.status] || response?.statusText;

  if (response && response.status && response.url) {
    const { status, url } = response;

    if (status === 401) {
      notification.error({
        message: '未登录或登录已过期，请重新登录。',
      });
      // @HACK
      /* eslint-disable no-underscore-dangle */
      window.g_app._store.dispatch({
        type: 'login/logout',
      });
      return;
    }
    notification.error({
      message: `request error ${status}: ${url}`,
      description: errortext,
    });
    // environment should not be used
    if (status === 403) {
      router.push('/exception/403');
    }
  }
};

const request = extend({
  errorHandler,
  credentials: 'include',
});

request.interceptors.request.use((url, options) => {
  let timeOut = 0;
  if (window.intervalId) {
    clearInterval(window.intervalId);
    window.intervalId = null;
  }
  window.intervalId = setInterval(() => {
    timeOut++;
    if (timeOut >= 1800) {
      router.replace('/user/login');
      clearInterval(window.intervalId);
    }
  }, 1000);
});

request.interceptors.response.use(async (response, options) => {
  let res;
  if (options.headers.blob) {
    res = await response.clone();
  } else {
    res = await response.clone().json();
  }
  if (res.code === '300000') {
    router.push('/user/login');
    return;
  }
  return response;
});

export default request;
