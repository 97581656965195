const MB = 1024 * 1024;
// http://192.168.33.223:9000/api/v1
// http://portal.1x0.cn:9000
// http://127.0.0.1:8091
// http://192.168.37.169:9080
const getBaseURL = () => {
  const { hostname } = window.location;
  if (hostname === 'localhost' || hostname === 'dev-onboarding.tts-janus.com') {
    return 'https://dev-commu.dev-janus.com';
  }
  if (hostname === 'sit-onboarding.tts-janus.com') {
    return 'https://sit-commu.tts-janus.com';
  }
  if (hostname === 'uat-onboarding.ars-janus.com') {
    return 'https://uat-commu.ars-janus.com';
  }
  if (hostname === 'driver.ars-janus.com') {
    return 'https://gate-commu.ars-janus.com';
  }
  return 'https://dev-commu.dev-janus.com';
};
export default {
  server: process.env.NODE_ENV === 'development' ? `${getBaseURL()}` : `${getBaseURL()}`,
  // server: process.env.NODE_ENV === 'development' ? `http://192.168.33.59:9200` : '',
  maxImageSize: MB * 3,
  maxBackgroundImageSize: MB * 5,
  maxAvatarSize: MB * 1.5,

  // Client default system setting
  primaryColor: '74, 144, 226',
  primaryTextColor: '247, 247, 247',
  // backgroundImage: require('@/assets/images/background.jpg'),
  sound: 'default',
};
