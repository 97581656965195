var _Object$getPrototypeOf = require("../core-js/object/get-prototype-of");

function _getPrototypeOf(o) {
  module.exports = _getPrototypeOf = _Object$getPrototypeOf || function _getPrototypeOf(o) {
    return o.__proto__;
  };

  return _getPrototypeOf(o);
}

module.exports = _getPrototypeOf;