/* eslint-disable */
import socket from '../socket';
import socketRequest from '../utils/socketRequest';
import { getUserData, getMCUserData, getMCGroupUserData } from '@/services/api';
import {
  getLiveChatMessage,
  getLiveChatUnreadMsg,
  getLiveChatUnreadMsgGroupByRemind,
  getMessageByRoom,
} from '@/services/communication';

export default {
  namespace: 'communication',

  state: {
    conversations: {},
    curruntConversation: { messages: [], id: '' },
    userData: [],
    liveChatUserData: [],
    mcUserData: [],
    mcGroupUserData: [],
    allChatRecords: [],
    unreadLiveChatCount: 0,
    unreadLiveChatMessage: [],
    liveChatMcGroupUserData: [],
  },

  effects: {
    *queryHistoryMessages({ payload }, { call, put }) {
      const payloadTemp = {
        ...payload,
        page: 1,
        pageSize: 10000,
      };
      const response = yield call(getMessageByRoom, payloadTemp);
      if (response && response.code === '000000') {
        const conversation = {};
        conversation[payload.subjectId] = response.data;
        yield put({
          type: 'updateConversationSet',
          payload: conversation,
        });
        yield put({
          type: 'updateCurruntConversation',
          payload: {
            id: payload.subjectId,
            messages: response.elements || response.data,
          },
        });
      } else {
        console.log(response.msg);
      }
    },
    *getChatRecords({ payload }, { call, put }) {
      const payloadTemp = {
        ...payload,
        page: 1,
        pageSize: 100000,
      };
      // const response = yield call(getLiveChatMessage, payloadTemp);
      // if (response && response.code === '000000') {
      //   yield put({
      //     type: 'updateChatRecords',
      //     payload: response.elements || response.data,
      //   });
      //   return Promise.resolve('ok');
      // } else {
      //   console.log(response.msg);
      //   return Promise.reject('error');
      // }
    },
    *removeChatRecords({ payload }, { put }) {
      yield put({
        type: 'updateRemoveChatRecords',
        payload: [],
      });
    },
    *liveChatSendMessage({ payload }, { put }) {
      const response = yield socketRequest('post/message/livechat', payload);
      if (response) {
        const { reminds = [] } = response;
        const { id, app } = reminds && reminds[0];
        const preData = {
          remindId: app + `:user:` + id,
          pageQuery: {
            num: 1,
            size: 1000000,
          },
        };
        yield put({
          type: 'getChatRecords',
          payload: preData,
        });
        return Promise.resolve(response);
      }
    },
    *getUnreadLiveChatCount({ payload }, { call, put }) {
      // const response = yield call(getLiveChatUnreadMsg, payload);
      // if (response && response.code === '000000') {
      //   yield put({
      //     type: 'updateUnreadLiveChatCount',
      //     payload: response.data,
      //   });
      // } else {
      //   console.log(response.msg);
      // }
    },
    *getUnreadLiveChatMessage({ payload }, { call, put }) {
      // const response = yield call(getLiveChatUnreadMsgGroupByRemind, payload);
      // if (response && response.code === '000000') {
      //   yield put({
      //     type: 'updateUnreadLiveChatMessage',
      //     payload: response.data,
      //   });
      // }
    },
    *markUnreadLiveChat({ payload }, { put }) {
      const response = yield socketRequest('put/livechat/read', payload.remindId);
      if (!payload.isNotNeedGetMessage) {
        if (response) {
          yield put({
            type: 'getUnreadLiveChatCount',
            payload: `DDI:user:${payload.clientUserId}`,
          });
          yield put({
            type: 'getUnreadLiveChatMessage',
            payload: `DDI:user:${payload.clientUserId}`,
          });
        }
      }
      return response;
    },

    *sendMessage({ payload }, { put }) {
      const response = yield socketRequest('post/message', payload);
      if (response) {
        yield put({
          type: 'updateNewMessage',
          payload: response,
        });
        return Promise.resolve(response);
      }
    },

    *fetchUserData({ payload }, { call, put }) {
      // const res = yield call(getUserData, payload);
      // if (res.code === '000000') {
      //   yield put({
      //     type: 'saveUserData',
      //     payload: res.data,
      //   });
      // }
      // return res;
    },
    *liveChatFetchUserData({ payload }, { call, put }) {
      // const res = yield call(getUserData, payload);
      // if (res.code === '000000') {
      //   yield put({
      //     type: 'liveChatSaveUserData',
      //     payload: res.data,
      //   });
      // }
      // return res;
    },

    *fetchMCUserData({ payload }, { call, put }) {
      // const res = yield call(getMCUserData, payload);
      // if (res.code === '000000') {
      //   yield put({
      //     type: 'saveMCUserData',
      //     payload: res.data,
      //   });
      // }
      // return res;
    },

    *fetchMCGroupUserData({ payload }, { call, put }) {
      // const res = yield call(getMCGroupUserData, payload);
      // if (res.code === '000000') {
      //   yield put({
      //     type: 'saveMCGroupUserData',
      //     payload: res.data,
      //   });
      // }
      // return res;
    },
    *liveChatFetchMCGroupUserData({ payload }, { call, put }) {
      // const res = yield call(getMCGroupUserData, payload);
      // if (res.code === '000000') {
      //   yield put({
      //     type: 'liveChatSaveMCGroupUserData',
      //     payload: res.data,
      //   });
      // }
      // return res;
    },
  },

  reducers: {
    updateCurruntConversation(state, action) {
      return {
        ...state,
        curruntConversation: action.payload,
      };
    },

    updateConversationSet(state, action) {
      return {
        ...state,
        conversations: { ...state.conversations, ...action.payload },
      };
    },

    updateNewMessage(state, action) {
      const {
        payload: { roomId },
      } = action;

      const data = state.curruntConversation.messages;
      if (data.length >= 1) {
        if (roomId === data[0].roomId) {
          data.unshift(action.payload);
        }
      } else {
        data.unshift(action.payload);
      }
      return {
        ...state,
        curruntConversation: { messages: data, id: '' },
      };
    },

    updateNewMessageBySocket(state, action) {
      const {
        payload: { roomId },
      } = action;
      const driverId = window.localStorage.getItem('driver-id');
      const currentRoomId = `DriverProfile:${driverId}`;
      const data = state.curruntConversation.messages;
      if (currentRoomId === roomId) {
        data.unshift(action.payload);
      }
      return {
        ...state,
        curruntConversation: { messages: data, id: '' },
      };
    },

    saveUserData(state, { payload }) {
      return {
        ...state,
        userData: payload,
      };
    },
    liveChatSaveUserData(state, { payload }) {
      return {
        ...state,
        liveChatUserData: payload,
      };
    },
    updateChatRecords(state, action) {
      return {
        ...state,
        allChatRecords: action.payload,
      };
    },
    updateRemoveChatRecords(state, action) {
      return {
        ...state,
        allChatRecords: action.payload,
      };
    },
    updateUnreadLiveChatCount(state, action) {
      return {
        ...state,
        unreadLiveChatCount: action.payload,
      };
    },
    updateUnreadLiveChatMessage(state, action) {
      return {
        ...state,
        unreadLiveChatMessage: action.payload,
      };
    },

    saveMCUserData(state, { payload }) {
      return {
        ...state,
        mcUserData: payload,
      };
    },
    saveMCGroupUserData(state, { payload }) {
      return {
        ...state,
        mcGroupUserData: payload,
      };
    },
    liveChatSaveMCGroupUserData(state, { payload }) {
      return {
        ...state,
        liveChatMcGroupUserData: payload,
      };
    },

    clear(state) {
      return {
        ...state,
        curruntConversation: { messages: [], id: '' },
      };
    },
  },
  subscriptions: {
    openSocket({ dispatch, history }) {
      console.log('=======open===================');
      history.listen(location => {
        const url = location.pathname;
        if (url === '/user/login') {
          socket.close();
        }
      });
      if (
        window.Notification &&
        (window.Notification.permission === 'default' ||
          window.Notification.permission === 'denied')
      ) {
        window.Notification.requestPermission();
      }
      socket.on('connect', async () => {
        console.log('===============socket connect===============');
        localStorage.setItem('LIVECHAT_ID', '');
        socketRequest('login', {}).then(res => console.log('Websocket Login Status:', res));
      });
      socket.on('disconnect', async () => {
        console.log('===============socket disconect===============');
      });

      socket.on('message/created', message => {
        // notification('new message', message.sender.avatar, message.content, Math.random());
        console.log(message);
        dispatch({
          type: 'updateNewMessageBySocket',
          payload: message,
        });
        const { type, sender = {}, reminds = [] } = message || {};
        const { id, app } = sender;
        // const { id, app } = reminds[0];
        const idClone = parseInt(id, 10);
        const clientUserId = localStorage.getItem('USER-ID');
        if (type === 3) {
          const chatId = localStorage.getItem('LIVECHAT_ID');
          const chatIdClone = parseInt(chatId, 10);
          if (chatIdClone === idClone) {
            const preData = {
              remindId: app + `:user:` + chatId,
              pageQuery: {
                num: 1,
                size: 1000000,
              },
            };
            const preData2 = {
              remindId: app + `:user:` + chatId,
              clientUserId,
              isNotNeedGetMessage: true,
            };
            dispatch({
              type: 'getChatRecords',
              payload: preData,
            });
            dispatch({
              type: 'markUnreadLiveChat',
              payload: preData2,
            });
          } else {
            dispatch({
              type: 'getUnreadLiveChatMessage',
              payload: `DDI:user:${clientUserId}`,
            });
          }
          dispatch({
            type: 'getUnreadLiveChatCount',
            payload: `DDI:user:${clientUserId}`,
          });
        }
      });

      socket.on('error', async e => {
        console.log('===============socket error===============');
        console.error(e);
      });
    },
  },
};
