import React from 'react';
import { Router as DefaultRouter, Route, Switch } from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@tmp/history';
import RendererWrapper0 from '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login',
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Login" */ '../User/Login'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Login').default,
        exact: true,
      },
      {
        path: '/user/forget',
        name: 'ForgetPassword',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__ForgetPassword" */ '../User/ForgetPassword'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../User/ForgetPassword').default,
        exact: true,
      },
      {
        path: '/user/register',
        name: 'register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Register" */ '../User/Register'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Register').default,
        exact: true,
      },
      {
        path: '/user/register-result',
        name: 'register.result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__RegisterResult" */ '../User/RegisterResult'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../User/RegisterResult').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
  {
    path: '/driverProfile',
    name: 'Driver Profile',
    component: __IS_BROWSER
      ? _dvaDynamic({
          app: require('@tmp/dva').getApp(),
          models: () => [
            import(/* webpackChunkName: 'p__DriverProfile__model.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/DriverProfile/model.js').then(
              m => {
                return { namespace: 'model', ...m.default };
              },
            ),
          ],
          component: () =>
            import(/* webpackChunkName: "p__DriverProfile" */ '../DriverProfile'),
          LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
            .default,
        })
      : require('../DriverProfile').default,
    hideInMenu: true,
    exact: true,
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        path: '/',
        redirect: '/dashboard/',
        exact: true,
      },
      {
        path: '/driverScore',
        name: 'Driver Score',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__ScorePage" */ '../ScorePage'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../ScorePage').default,
        hideInMenu: true,
        exact: true,
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__DriverMessage" */ '../DriverMessage'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../DriverMessage').default,
        routes: [
          {
            path: '/driverMessage',
            redirect: '/driverMessage/driverMessageDetail',
            exact: true,
          },
          {
            path: '/dashboard',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__DriverMessage__Detail__" */ '../DriverMessage/Detail'),
                  LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                    .default,
                })
              : require('../DriverMessage/Detail').default,
            exact: true,
          },
        ],
      },
      {
        path: '/workOrder',
        name: 'Work Order',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__WorkOrder__ServiceHistory" */ '../WorkOrder/ServiceHistory'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkOrder/ServiceHistory').default,
        exact: true,
      },
      {
        hideInMenu: true,
        path: '/workOrder/detail/:id/:tab',
        name: 'workOrderDetail',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__WorkOrder__Detail__model.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/WorkOrder/Detail/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__WorkOrder__Detail" */ '../WorkOrder/Detail'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../WorkOrder/Detail').default,
        exact: true,
      },
      {
        path: '/financial',
        name: 'Financial',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Financial" */ '../Financial'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Financial').default,
        routes: [
          {
            path: '/financial/AR',
            name: 'AR',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Financial__FinancialAR" */ '../Financial/FinancialAR'),
                  LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                    .default,
                })
              : require('../Financial/FinancialAR').default,
            exact: true,
          },
          {
            path: '/financial/financialAR/detail/:id',
            name: 'driverDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Financial__FinancialAR__detail" */ '../Financial/FinancialAR/detail'),
                  LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                    .default,
                })
              : require('../Financial/FinancialAR/detail').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/financial/financialAR/detail/overtimePay/:id',
            name: 'driverDetail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Financial__FinancialAR__detail__overtimePay" */ '../Financial/FinancialAR/detail/overtimePay'),
                  LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                    .default,
                })
              : require('../Financial/FinancialAR/detail/overtimePay').default,
            hideInMenu: true,
            exact: true,
          },
          {
            path: '/financial/compensation',
            name: 'Compensation',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Financial__Compensation" */ '../Financial/Compensation'),
                  LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                    .default,
                })
              : require('../Financial/Compensation').default,
            exact: true,
          },
        ],
      },
      {
        path: '/service',
        name: 'Service',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Service" */ '../Service'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Service').default,
        exact: true,
      },
      {
        path: '/compliance',
        name: 'Compliance',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Compliance" */ '../Compliance'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Compliance').default,
        exact: true,
      },
      {
        path: '/resource/driver',
        name: 'Resources',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__Resources__model.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/Resources/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__Resources" */ '../Resources'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../Resources').default,
        exact: true,
      },
      {
        name: 'exception',
        icon: 'warning',
        path: '/exception',
        hideInMenu: true,
        routes: [
          {
            path: '/exception/403',
            name: 'not-permission',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__403" */ '../Exception/403'),
                  LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/403').default,
            exact: true,
          },
          {
            path: '/exception/404',
            name: 'not-find',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__404" */ '../Exception/404'),
                  LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/404').default,
            exact: true,
          },
          {
            path: '/exception/500',
            name: 'server-error',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Exception__models__error.js' */ '/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/pages/Exception/models/error.js').then(
                      m => {
                        return { namespace: 'error', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "p__Exception__500" */ '../Exception/500'),
                  LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                    .default,
                })
              : require('../Exception/500').default,
            exact: true,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('/home/ec2-user/.jenkins/workspace/uat-janus-driver-onboarding-fe/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    routeChangeHandler(history.location);
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
