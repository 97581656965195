import { stringify } from 'qs';
import request from '@/utils/request';

export async function queryProjectNotice() {
  return request('/api/project/notice');
}

export async function queryFakeList(params) {
  return request(`/api/fake_list?${stringify(params)}`);
}

export async function removeFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    data: {
      ...restParams,
      method: 'delete',
    },
  });
}

export async function addFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    data: {
      ...restParams,
      method: 'post',
    },
  });
}

export async function updateFakeList(params) {
  const { count = 5, ...restParams } = params;
  return request(`/api/fake_list?count=${count}`, {
    method: 'POST',
    data: {
      ...restParams,
      method: 'update',
    },
  });
}

export async function fakeAccountLogin(params) {
  return request('/api/login/account', {
    method: 'POST',
    data: params,
  });
}

export async function fakeRegister(params) {
  return request('/api/register', {
    method: 'POST',
    data: params,
  });
}

export async function queryNotices(params = {}) {
  return request(`/api/notices?${stringify(params)}`);
}

export async function getFakeCaptcha(mobile) {
  return request(`/api/captcha?mobile=${mobile}`);
}

// Login
export async function AppLogin(params) {
  return request('/apidv/auth/driver/candidate/login', {
    method: 'POST',
    data: params,
  });
}
// LoginOut
export async function AppLogout(params) {
  return request('/apidv/auth/driver/candidate/logout', {
    method: 'POST',
    data: params,
  });
}

// change password
export async function changePwd(params) {
  return request('/apidv/driver/candidate/chang/password', {
    method: 'POST',
    data: params,
  });
}

export async function apiGetAllState(params) {
  return request('/apidv/master/market/state/list', {
    method: 'POST',
    data: params || {},
  });
}

export async function apiGetCityByName(params) {
  return request('/apidv/master/market/city/listByName', {
    method: 'POST',
    data: params,
  });
}

export async function getDriverDetail(params) {
  return request('/apidv/driver/candidate/get', {
    method: 'POST',
    data: params || {},
  });
}

export async function apiDeleteImg(params) {
  return request('/apidv/driver/candidate/file/delete', {
    method: 'POST',
    data: params || {},
  });
}

export async function apiGetVehicleCascade(params) {
  return request('/apidv/master/driver/vehicle/cascade', {
    method: 'POST',
    data: params || {},
  });
}

export async function apiSkillConfirm(params) {
  return request('/apidv/driver/candidate/skill/confirm', {
    method: 'POST',
    data: params || {},
  });
}

export async function apiRescueEquipmentSubmit(params) {
  return request('/apidv/driver/candidate/rescue-equipments', {
    method: 'POST',
    data: params || {},
  });
}
